.examenkandidaat-row {
  .tpb {
    border: 1px solid #f2f2f2;
    display: inline-block;
    padding: 0 5px;
    font-size: 12px;
  }

  .fa-times {
    color: #d87e8f;
  }

  .fa-check {
    color: #afd09a;
  }

  .fa-close {
    color: #d87e8f;
  }
}
