@import "app/stylecomponents/mixins";
@import "app/stylecomponents/layering";

.examenblokToevoegen {
  .profileControl {
    padding-top: 30px;

    input {
      width: 100%;
    }

    input[type="radio"] {
      width: auto;
    }

    .switch {
      width: auto;
      margin-bottom: 0;
    }

    label {
      width: 100%;
    }
  }

  .dagdeel {
    display: inline;
    font-weight: 100;
  }

  .dagdelen {
    padding-top: 30px;

    .dagdeel {
      /*padding-right: 20px;*/
    }
  }

  .radio {
    margin-right: 10px;
  }
}
