﻿@import "../_themesettings.scss";

.ctaBox {
  // https://stackoverflow.com/a/25517025/2764925
  margin-bottom: -1 * ($grid-gutter-width/2);
  margin-top: 50px;

  .stickyRight {
    float: right;
  }

  .backButtonCol {
    margin-bottom: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .ctaButtonCol {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  @media screen and (min-width: $screen-sm-min) {
    background-color: #fbfbfc;
    border-top: solid 2px #f3f3f3;

    .backButtonCol {
      margin-top: 30px;
    }
  }

  &.ctaConfirmBox {
    background-color: #fd8702;

    .ctaButtonCol button {
      background-color: white;
      color: #fd8702;
    }

    .backButtonCol a {
      color: white;
    }
  }
}

.ctaConfirmBoxInfo {
  margin-bottom: -50px;
  margin-top: 50px;
  height: 100px;
  background-color: #fafaff;
}
