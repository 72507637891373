.secretariaatOverzichtExamens {
	.lijstTransportBlokje {
		color: #a5aeb5;
		border: solid 2px #f2f2f2;
		font-weight: bold;
		padding: 2px 2px 2px 2px;
	}

	.orange {
		color: #ff8802;
	}

	.grey {
		color: #89969e;
	}

	.pointer {
		cursor: pointer;
	}

	.examenOverzichtSecretariaatCta {
		text-align: right;
	}

	.tpb {
		border-left-color: #0094ff;
		border-left-width: 5px;
		border-left-style: solid;
	}
}

.switch {
	margin-left: 0;
}
