.tegoedboncodeForm {
	.profileControl {
		margin-bottom: 20px;
	}
	.input-icon {
		position: relative;
	}

	.input-icon > span {
		position: absolute;
		display: block;
		transform: translate(2px, -50%);
		top: 52%;
		pointer-events: none;
		width: 25px;
		text-align: center;
		font-weight: bold;
		color: #89969f;
		line-height: 1;
		font-size: 16px;
	}

	.input-icon > .form-control {
		padding-left: 25px;
	}

	button {
		float: right;
	}
}
