﻿@import "../themesettings";
//
// All mixins in theme. Please define cross-browser mixins for use in application. See: http://caniuse.com
//

//
// Define a box-sizing behaviour, defaulting to the default setting in theme
//
@mixin box-sizing($box-model: $default-box-sizing) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

//
// Define a border-radius, defauling to the default setting in theme
//
@mixin border-radius($radius: $default-border-radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

//
// Return a default or customized border on one, multiple or all sides
// @param {string} $style Define style like i.e. solid 2px #ddd
// @param {string} $sides Define optional sides to have to border appear on like i.e. top bottom
//
@mixin border($style, $sides...) {
  // check whether the choise is to return the default border style or a custom one
  @if ($style != "default") {
    // check whether the sides to have the border appearing on are defined, if not, set the border around whole object
    @if ($sides) {
      @each $side in $sides {
        @if (
          $side ==
            "top" or
            $side ==
            "right" or
            $side ==
            "bottom" or
            $side ==
            "left"
        ) {
          border-#{$side}: $style;
        }
      }
    } @else {
      border: $style;
    }
  } @else {
    // set the border style to the default style of the theme
    $style: $default-border-style
      $default-border-thickness
      $default-border-color;

    // check whether the sides to have the border appearing on are defined, if not, set the border around whole object
    @if ($sides) {
      @each $side in $sides {
        @if (
          $side ==
            "top" or
            $side ==
            "right" or
            $side ==
            "bottom" or
            $side ==
            "left"
        ) {
          border-#{$side}: $style;
        }
      }
    } @else {
      border: $style;
    }
  }
}
