.deelnemerBeoordeling {
	.onderdeelOpmerking {
		margin-bottom: 20px;
	}
	.onvoldoende {
		color: red;
	}
}

.not-subbed-message {
	background-color: #e3f0ff;
	border: solid 2px #74acee;
	display: flex;
	width: 100%;
	min-height: 70px;
}

.not-subbed-message__body {
	display: flex;
	align-items: center;
	width: 100%;
	color: #74acee;
	font-weight: bold;
	padding-left: 25px;
}
