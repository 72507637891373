﻿@import "mixins";
@import "layering";

.tab-bar-container {
  margin-top: -30px;
  padding: 0;
  background-color: #f4f4f7;
  border-bottom: 3px solid #dee3e7;
  margin-bottom: 30px;

  .tab-bar {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    padding-left: ($grid-gutter-width/2);
    padding-top: 12px;

    .tab {
      height: 37px;
      position: relative;
      top: 3px;
      display: inline-block;
      color: #a5aeb5;
      margin-right: 20px;
      padding-right: 20px;

      i {
        margin-right: 10px;

        &.notificationbol {
          color: #ff8802;
          font-size: 10px;
          top: -7px;
          left: 5px;
        }
      }

      a {
        padding-bottom: 16px;
        color: #a5aeb5;
        text-decoration: none;
        font-weight: bold;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .tab.active {
      color: #333333;
      border-bottom: 3px solid #ff8802;
      font-weight: bold;

      a {
        color: #333333;
      }
    }
  }
}

// remove space above the tabbar when on tablet
@media only screen and (min-width: $screen-sm-min) {
  @media only screen and (max-width: $screen-sm-max) {
    .tab-bar-container {
      margin-top: -1 * ($grid-gutter-width/2);
      margin-bottom: ($grid-gutter-width/2);
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .tab-bar-container {
    .tab-bar {
      padding-left: ($grid-gutter-width); // double gutter
      margin-left: 8.33%; // the width of 1 column
      width: auto;

      .tab {
        padding-left: 0;
        width: auto;
        margin-left: 0;
      }
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .tab-bar-container {
    margin-bottom: 40px;
    margin-top: -64px;

    .tab-bar {
      font-size: 12px;

      .tab {
        i {
          margin-right: 15px;
        }
      }
    }
  }
}
