﻿@import "../themesettings";
@import "mixins";

//
// Include necessary files for Bootstraps grid system
//
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

//
// Include grid system
//
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/grid";

//
// Include responsive utilities
//
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

//
// Reset the box-sizing
//
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 768px;
  }
}

//
// Row with equal height columns
//
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
