﻿@import "mixins";
@import "layering";

// textual input styling
input[type="text"],
input[type="password"],
input[type="email"] {
	padding: 10px;
	background: #f5f6fa;
	border: solid 2px #d6dde2;
	font-weight: bold;
	@include box-sizing(border-box);
	color: #89969f;
	line-height: 1;
	font-size: 14px;
	height: 40px;
	width: 100%;

	&:focus {
		outline: none;
		border-color: #ff8800;
		background: #fff;
	}

	&.ng-invalid {
		border-color: #e29fab;
		color: #d87e8f;
		background-color: #f9edee;

		&.ng-pristine {
			color: #bbb;

			&::-webkit-input-placeholder {
				color: #bbb;
				opacity: 1;
				font-weight: normal;
			}

			&::-moz-placeholder {
				color: #bbb;
				opacity: 1;
				font-weight: normal;
			}

			&:-moz-placeholder {
				color: #bbb;
				opacity: 1;
				font-weight: normal;
			}

			&:-ms-input-placeholder {
				color: #bbb;
				opacity: 1;
				font-weight: normal;
			}
		}
	}

	&.ng-valid {
		background-repeat: no-repeat;
		background-position: right 11px top 11px;
		background-size: 20px;
	}

	&:disabled {
		outline: none;
		background: #edeef2;
		color: #c5cbd0;
		cursor: not-allowed;
	}
}

::-webkit-input-placeholder {
	color: #bbb;
	opacity: 1;
	font-weight: normal;
}

::-moz-placeholder {
	color: #bbb;
	opacity: 1;
	font-weight: normal;
}

:-moz-placeholder {
	color: #bbb;
	opacity: 1;
	font-weight: normal;
}

:-ms-input-placeholder {
	color: #bbb;
	opacity: 1;
	font-weight: normal;
}
// enables pointer cursor on labels and checkboxes for extra usability
label,
input[type="checkbox"] {
	cursor: pointer;
}

label {
	font-weight: bold;
	margin-bottom: 15px;
	line-height: 1;
	display: inline-block;
}
// invalid form styling
form input.ng-invalid {
	background: #fcdbdb;
}
// untouched/pristine form styling
form input.ng-pristine.ng-invalid {
	background: #f5f6fa;
	border-color: #d6dde2;

	&:focus {
		background: #fff;
		border-color: #ff8800;
	}
}
// css reset for removing rounded corners on ios
textarea,
input[type="text"],
input[type="button"],
input[type="email"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}
// inline input (text/email/etc.) errors that lay over the page
.inputErrors {
	position: absolute;
	background: none;
	border: none;
	margin-top: 10px;
	z-index: $inline-form-errors;

	div {
		background: #e06e84;
		min-height: 40px;
		font-weight: 600;
		@include box-sizing(border-box);
		padding: 10px;
		color: #fff;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 10px;
			height: 10px;
			transform: rotate(45deg);
			left: 10px;
			top: -5px;
			background: #e06e84;
		}

		a {
			color: #fff;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.row.form-group {
	label {
		width: 100%;
		display: block;
	}

	[type="text"],
	[type="email"],
	[type="number"],
	[type="password"] {
		width: 100%;
	}
}

/*
    Radiobuttons Aanstellingspas
*/
.radio-buttons {
	text-align: left;

	span {
		margin-right: 30px;
	}

	label {
		text-align: left;
		padding-left: 30px;
		@include box-sizing(border-box);
		height: 20px;
		line-height: 20px;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		font-weight: normal;

		&:before {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			@include border-radius(10px);
			@include box-sizing(border-box);
			border: solid 2px #b6b6b6;
			position: absolute;
			top: 0;
			left: 0;
			background: #ebebeb;
			transition: background 0.2s ease;
		}

		&.active {
			font-weight: bold;

			&:after {
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				@include border-radius(5px);
				@include box-sizing(border-box);
				background: #333;
				top: 5px;
				left: 5px;
				position: absolute;
				transition: background 0.2s ease;
			}
		}
	}

	input[type="radio"] {
		position: absolute;
		left: -9999px;

		&:focus ~ label:before {
			box-shadow: 0px 0px 3px #ff8800;
			border-color: #ff8800;
		}
	}
}
/*
    Textarea
*/

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px !important;
	height: 34px;
	margin-left: 20px;
	margin-right: 20px;
}

/* Hide default HTML checkbox */
.switch input {
	display: none;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider.color {
	background-color: #fd8702;
}
input:checked + .slider.nocolor {
	background-color: #ccc !important;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.sliderspan {
	display: inline-block;
	vertical-align: middle;
	margin-top: -55px;
	-webkit-transition: 0.4s;
	transition: 0.4s;

	&.selected {
		font-weight: bold;
	}
}
/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
textarea {
	color: #89969f;
	width: 100%;
	min-height: 100px;
	resize: vertical;
	padding: 10px;
	background: #f5f6fa;
	font-weight: bold;
	@include box-sizing(border-box);
	border: solid 2px #d6dde2;
	&:focus {
		outline: none;
		border-color: #ff8800;
		background: #fff;
	}

	&::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: #bbb;
		opacity: 1;
		font-weight: normal;
	}

	&:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #bbb;
		opacity: 1;
		font-weight: normal;
	}

	&::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #bbb;
		opacity: 1;
		font-weight: normal;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #bbb;
		opacity: 1;
		font-weight: normal;
	}
}

.horizontal-divider {
	border: solid 1px #f3f3f3;
	min-height: 1px;
	margin-top: 15px;
}

.formControl {
	padding-top: 30px;
}
