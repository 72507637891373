@import "app/_themesettings.scss";
@import "app/stylecomponents/_layering";
@import "app/stylecomponents/_modal";

.secretariaatOverzichtExamens {
  .lijstTransportBlokje {
    color: #a5aeb5;
    border: solid 2px #f2f2f2;
    font-weight: bold;
    padding: 2px 2px 2px 2px;
  }

  .orange {
    color: #ff8802;
  }

  .grey {
    color: #89969e;
  }

  .pointer {
    cursor: pointer;
  }

  .examenOverzichtSecretariaatCta {
    text-align: right;
  }
}
