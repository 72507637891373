.passen-item-container {
	border-collapse: collapse;
	width: 100%;
	margin-top: 26px;

	.pasType {
		border: solid 2px #f2f2f2;
		font-weight: bold;
		padding: 2px 2px 2px 2px;

		&.tpb {
			color: #a5aeb5;
		}
	}

	tr {
		border-bottom: solid 2px #f2f2f2;
		cursor: pointer;

		&.opened {
			background-color: #f5f5f5;
		}

		&:hover {
			background-color: #f5f5f5 !important;
		}

		&.legacy {
			border-left-color: #a5aeb5;
			border-left-width: 5px;
			border-left-style: solid;
		}

		&.tpb {
			border-left-color: #0094ff;
			border-left-width: 5px;
			border-left-style: solid;
		}
		&.notdownloaded {
			border-left-color: #77dd77;
			border-left-width: 5px;
			border-left-style: solid;
		}
		&.ongeldig {
			border-left-color: #ff8181;
			border-left-width: 5px;
			border-left-style: solid;
		}
		&.anonymized {
			border-left-color: #ffdf52;
			border-left-width: 5px;
			border-left-style: solid;
		}
		&.other {
			border-left-color: #ffffff;
			border-left-width: 5px;
			border-left-style: solid;
		}
	}

	td {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	td.checkbox-column {
		margin-right: 0px;
	}

	td.name-column {
	}

	td.pasnummer-column {
	}

	td.opener {
		float: right;
		font-size: 12px;
		margin-top: -40px;
		i {
			margin-left: 20px;
			color: black;
			cursor: pointer;
			font-size: 18px;

			&.opened {
				-moz-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;
				-moz-transform: rotate(90deg);
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				-o-transform: rotate(90deg);
			}

			&.closed {
				-moz-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;
				-moz-transform: rotate(0deg);
				-webkit-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				-o-transform: rotate(0deg);
			}
		}
	}

	td.extra-info-row {
		transform: scale(1); /* you need a scale here to allow it to transition in both directions */
		transition: 0.5s all ease;
		background-color: #f5f5f5;
		overflow: hidden;
		color: black;
		font-weight: normal;

		&.hiddenAnimatable {
			max-height: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&.visible {
			/*border-top: solid 2px #ddd;*/
			max-height: 600px;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.row {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.afkeurreden-row {
			color: #e06e84;
			font-weight: bold;

			[class^="col-"] {
				text-decoration: none !important;
				text-wrap: wrap;
			}
		}
	}

	.foundpas-item {
		cursor: pointer;

		.green {
			color: #9dcc89;
			font-weight: bold;
		}

		.red {
			color: #e06e84;
			font-weight: bold;
		}

		.orange {
			color: #f5a623;
			font-weight: bold;
		}

		&.ongeldig {
			color: #e06e84;

			[class^="col-"] {
				text-decoration: line-through;
			}
		}
	}

	thead {
		font-weight: bold;
	}
}

.pasOngeldigMakenModal {
	label {
		font-weight: normal;
		margin-bottom: 10px;
	}

	input[type="radio"] {
		margin-bottom: 1px;
		margin-right: 20px;
	}

	input[type="text"] {
		margin-top: 10px;
	}

	.reasonPolice {
		margin-top: 10px;
	}
}

.afkeurButton {
	background-color: #e06e84;
	color: #fff;

	&:hover {
		background-color: lighten(#e06e84, 8%);
	}

	&:active {
		background-color: darken(#e06e84, 8%);
	}

	&:disabled {
		background-color: #d7d7d7;
		color: #ffffff;
	}
}

.dupliceerPasButton {
	background-color: #9dcc89;
	color: #fff;
	margin-right: 15px;

	&:hover {
		background-color: lighten(#9dcc89, 8%);
	}

	&:active {
		background-color: darken(#9dcc89, 8%);
	}

	&:disabled {
		background-color: #d7d7d7;
		color: black;
	}
}

.vrijstellingButton {
	background-color: #9dcc89;
	color: #fff;
	margin-right: 15px;

	&:hover {
		background-color: lighten(#9dcc89, 8%);
	}

	&:active {
		background-color: darken(#9dcc89, 8%);
	}

	&:disabled {
		background-color: #d7d7d7;
		color: black;
	}
}
