﻿/*
 * Declaration of z-indexes of all modules
 * z-index dertermines vertical position of absolute elements
 * Choose between 0 and 9999 where 0 is the bottom and 9999 the top
 * Structure this document so that the highest positioned elements are at the top of the document
 */

//
// App loading bar: always on top
//
$app-loading-bar-spinner: 9999;
$app-loading-bar: 9998;
$bootstrapping-app-loading-bar: 9997;
$modal-form-elements: 9088;
$modal: 9087;
$cropper: 9086;

$ui-blocker: 9085;
$overlay-background: 9085;

$z-index-mobile-navigation: 9082;

$notification-box: 9081;

$main-header: 9080;

$inline-form-errors: 9050;

$fixed-bottom-navigation: 8001;

$z-index-forms: 7000;

$fixed-bottom-form: 6000;

$hidden-element: -1;
