.keur-bvo {
  .keuringButton {
    .bvoEisen {
      line-height: 30px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      img {
        margin: 3px 10px 0 0;
        display: block;
        float: left;
      }
    }
  }

  .keurSection {
    .keurChecks {
      .pretty {
        &.goedkeur-vinkje {
          margin-bottom: 30px;
        }
      }
    }
  }

  .bvo-document-preview-row {
    border: solid 2px #f2f2f2;
    margin-top: 20px;
    margin-bottom: 5px;
    overflow: auto;
    padding-top: 25px;
    padding-bottom: 23px;
    min-height: 90px;

    .fa {
      margin-right: 30px;
      color: #cccfd2;

      &.fa-eye {
        margin-left: 20px;
        color: #4a4a4a;
      }

      &.fa-times {
        color: #e06e84;
      }

      &.fa-check {
        color: #9dcc89;
      }
    }

    .document-keur-buttons {
      text-align: right;
    }
  }

  .afkeurRedenPreview {
    li:first-child {
      margin-top: -8px;
    }
  }

  .afkeur-redenen {
    text-align: left;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        line-height: 30px;
      }
    }

    textarea {
      width: 100%;
    }
  }

  .amountBvosRemaining {
    text-align: center;
    border-top: solid 1px #ddd;

    p {
      font-weight: bold;
    }
  }

  .nieuw {
    border: solid 3px #82de30;
    background: #fff;

    .documentContainer {
      ul {
        li:first-of-type {
          padding-left: 20px;
        }
      }
    }
  }

  .afkeuring {
    border: solid 3px #ddd;
    background: #fff;

    .row {
      padding: 0;
      border-bottom: solid 1px #ddd;
    }

    h4 {
      img {
        display: block;
        float: left;
        margin-top: -4px;
        margin-right: 20px;
      }
    }

    textarea {
      width: 100%;
      min-height: 120px;
      resize: vertical;
    }
  }

  .documentContainer {
    ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;

      li {
        float: left;
        padding: 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;

        &:first-of-type {
          padding-left: 0;
        }

        a {
          width: 100%;
          text-align: center;
          display: block;
        }
      }
    }
  }

  .beslissing {
    background: #fff;
    margin-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 30px;
  }

  .goedkeurButton {
    background: #9dcc89;
    color: #fff;
    margin-left: 20px;

    &:hover {
      background-color: lighten(#9dcc89, 8%);
    }

    &:active {
      background-color: darken(#9dcc89, 8%);
    }

    &:disabled {
      background-color: #d7d7d7;
      color: #ffffff;
    }
  }

  .afkeurButton {
    background-color: #e06e84;
    color: #fff;

    &:hover {
      background-color: lighten(#e06e84, 8%);
    }

    &:active {
      background-color: darken(#e06e84, 8%);
    }

    &:disabled {
      background-color: #d7d7d7;
      color: #ffffff;
    }
  }

  .afkeurConfirmButtons {
    text-align: center;
    margin-top: 40px;

    button {
      margin-right: 20px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .afkeurConfirm {
    ul {
      li {
        position: relative;
        padding: 10px;

        &.active {
          background-color: lighten(#ff8800, 8%);
          color: #fff;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        input[type="checkbox"] {
          position: absolute;
          left: 10px;
          top: 16px;
        }

        strong {
          font-weight: normal;
        }

        label {
          display: block;
          width: 100%;
          padding-left: 40px;
          line-height: 1.6em;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin-bottom: 0;
          font-weight: normal;
        }

        p {
          margin-bottom: 10px;
        }

        ul {
          list-style-type: disc;
          padding-left: 18px;
        }
      }
    }

    textarea {
      min-height: 150px;
      padding: 4px;
      resize: vertical;
    }
  }

  .keuringshistorie {
    margin-top: 20px;
    padding: 0;

    .col-12 {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .confirmScherm {
    text-align: left;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    textarea {
      width: 100%;
    }
  }

  .noteField {
    margin-top: 40px;
  }

  @media only screen and (min-width: 768px) {
    .keuringButton {
      .bvoEisen {
        line-height: 30px;
        position: absolute;
        width: auto;
        margin-bottom: 0;
        right: 30px;
        top: 30px;

        img {
          margin: 3px 10px 0 0;
          display: block;
          float: left;
        }
      }
    }
  }
}
