.colpreviews {
  div {
    text-align: center;
    background: #0094ff;
    border: solid 2px #fff;
    padding: 20px;
    color: #fff;
    font-weight: bold;
  }
}

.testMobile {
  width: 768px;
  div {
    width: 100%;
  }
}
