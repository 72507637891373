﻿@import "mixins";
@import "layering";

/*
	Overrides for select2
*/

.select2-container {
  display: block;
  color: #a5aeb5;

  .select2-choice {
    color: #a5aeb5;
    height: 40px;
    width: 100%;
    border: 2px solid #edeef2;
    background: none;
    background-image: none;
    border-radius: 0;
    padding-right: 25px;

    & > .select2-chosen {
      margin-right: 0;
      height: 36px !important;
      padding: 4px;
      font-weight: 500;
    }

    span.select2-arrow {
      margin-top: 2px;
      border-left: none;
      background: none;
      background-image: none;
    }
  }

  .select2-arrow {
    b {
      background: url(../images/fa-chevron-down.svg) !important;
      background-size: 13px !important;
      background-repeat: no-repeat !important;
      background-position-y: 10px !important;
      background-position-x: 10px !important;
      opacity: 0.4;
    }

    width: 30px !important;
  }
}

.select2-container-disabled {
  .select2-choice {
    background-color: #edeef2 !important;
    color: #c5cbd0 !important;
    border: 2px solid #ddd !important;

    .select2-arrow {
      background-color: #edeef2 !important;
    }
  }
}

//overrides for select2 retina styling
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 2dppx) {
  .select2-container .select2-choice .select2-arrow b,
  .select2-container .select2-choice abbr,
  .select2-search input,
  .select2-search-choice-close {
    background: url(../images/fa-chevron-down.svg) no-repeat !important;
    background-size: 12px !important;
    background-position-y: 8px !important;
    background-position-x: 10px !important;
    opacity: 0.4;

    @media only screen and (min-width: 768px) {
      background-position-y: 10px !important;
      background-position-x: 10px !important;
    }
  }
}

.select2-search input {
  height: 26px !important;
  background-position: 100% -22px !important;

  @media only screen and (min-width: 768px) {
    height: 36px !important;
  }
}

.select2-drop {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  z-index: $modal-form-elements; // to make sure the dropdown doesnt float above the header etc
  &.select2-drop-active {
    border-left: 2px solid #edeef2;
    border-bottom: 2px solid #edeef2;
    border-right: 2px solid #edeef2;
  }
}

.select2-container-active .select2-choice {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-results .select2-highlighted {
  background-color: #f80;
}

.select2-drop-mask {
  z-index: $z-index-forms;
}
