.account-keuring {
  .knopbalk {
    background-color: #fbfbfc;
    margin-bottom: -50px;
    min-height: 100px;
    border-top: solid 2px #f2f2f2;
    padding-top: 30px;
    padding-left: 30px;
    padding-top: 30px;
  }

  .goedkeurButton {
    background: #9dcc89;
    color: #fff;
    margin-left: 20px;

    &:hover {
      background-color: lighten(#9dcc89, 8%);
    }

    &:active {
      background-color: darken(#9dcc89, 8%);
    }

    &:disabled {
      background-color: #d7d7d7;
      color: #ffffff;
    }
  }

  .afkeurButton {
    background-color: #e06e84;
    color: #fff;

    &:hover {
      background-color: lighten(#e06e84, 8%);
    }

    &:active {
      background-color: darken(#e06e84, 8%);
    }

    &:disabled {
      background-color: #d7d7d7;
      color: #ffffff;
    }
  }
}
