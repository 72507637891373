.snoozeInfo {
  background-color: #edeef2;
  padding: 15px 15px 5px 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  span {
    margin-top: 20px;
    color: #fd8702;
    font-weight: bold;
  }
}

.snooze-data {
  color: #fd8702;
  padding-right: 8px;
}

.snoozecomment {
  margin: 0;
}
