﻿@import "mixins";
@import "../_themesettings.scss";
h1 {
  color: #747d84;

  &.pageTitle {
    font-size: 1.3em;
    margin-top: ($grid-gutter-width/2);
    margin-bottom: ($grid-gutter-width/2);
    margin-left: 0;
    margin-right: 0;
  }

  .subtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #b6b6b6;
    display: block;
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
}

h3 {
  text-transform: uppercase;
  font-size: 0.9em;
  color: #9a9a9a;
}

.capitalize-first-letter {
  &:first-letter {
    text-transform: capitalize;
  }
}

ul.vinkjes {
  margin: 0 0 0 10px;
  padding: 0;

  li {
    list-style-type: none;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
    padding-left: 1.2em;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      height: 1em;
      width: 1em;
      left: -10px;
      background-image: url("/assets/images/vinkje.svg");
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 0.8em;
      margin-top: 5px;
    }
  }
}

.text-center {
  text-align: center;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
    Mobile
*/

html {
  font-size: 14px;
  line-height: 1.857;
  color: #333;

  // antialiassing for OSX (no support on other sys' ?)
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.6em;
  line-height: 1.4em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 0.857em;
}

/*
    Tablets
*/
@media only screen and (min-width: $screen-sm-min) {
  html {
    font-size: 14px;
  }

  h1 {
    .subtitle {
      display: inline;
      width: auto;
      margin-left: 30px;
      margin-top: 0;
    }

    &.pageTitle {
      margin-left: ($grid-gutter-width/2);
    }
  }
}

/*
    Small desktops
*/
@media only screen and (min-width: $screen-md-min) {
  html {
    font-size: 14px;
  }

  h3 {
    font-size: 0.857em;
  }
}

/*
    Large desktops
*/
@media only screen and (min-width: $screen-lg-min) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.45em;
  }
}
