﻿@import "../themesettings";

// regular buttons
button {
  /*width: 100%;*/
  font-weight: bold;
  color: #333;
  padding-top: 10px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 10px;
  display: inline-block;
  cursor: pointer;
  border: none;
  font-size: 0.8em;
  text-transform: uppercase;
  /*min-width: 200px;*/
  &:hover {
    background-color: lighten(#ddd, 8%);
  }

  &:disabled {
    background: #ddd;
    color: #9f9f9f;
    cursor: not-allowed;

    &:hover {
      background-color: lighten(#ddd, 12%);
    }
  }

  &.primary {
    background-color: #ff8800;
    color: #fff;

    &:hover {
      background-color: lighten(#ff8800, 8%);
    }

    &:disabled {
      background-color: #ddd;
      color: #9f9f9f;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: transparent;
    color: #ff8800;
    border: solid 2px #ff8800;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #ffab4c;
      background-color: transparent;
      border-color: #ffab4c;
    }

    &:active {
      text-decoration: none;
      color: #ff8800;
      background-color: transparent;
      border-color: #ff8800;
    }

    &:disabled {
      background-color: transparent;
      color: #d0d5d9;
      border-color: #d0d5d9;
      cursor: not-allowed;
    }
  }

  &.large {
    font-size: 1.25em;
    padding: 6px 12px;

    &:disabled {
      background-color: #ddd;
      color: #9f9f9f;
      cursor: not-allowed;
    }
  }

  &.small {
    padding: 2px 6px;

    &:disabled {
      background-color: #ddd;
      color: #9f9f9f;
      cursor: not-allowed;
    }
  }

  &.arrow {
    &:before {
      content: "\f0a9";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.arrowBack {
    &:before {
      content: "\f0a8";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.file-text {
    &:before {
      content: "\f0f6";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.upload {
    background-color: #4a4a4a;

    &:before {
      content: "\f093";
      font-family: FontAwesome;
      padding-right: 15px;
    }

    &:hover,
    &:focus {
      background-color: #717171;
    }
  }

  &.callToAction {
    margin: 30px;
    float: right !important;
  }

  &.cart {
    &:before {
      content: "\f07a";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.check {
    &:before {
      content: "\f00c";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.icon-right {
    i {
      margin-left: 40px;
      margin-right: 0;
    }
  }

  &.icon-left {
    i {
      margin-right: 40px;
      margin-left: 0;
    }
  }
}

// anchor styling with button classes
a {
  color: #333;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: #717171;
  }

  &.cta {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;

    i {
      margin-right: 10px;
    }
  }

  &.callToActionBack {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0px;
    float: left !important;
  }

  i {
    margin-right: 10px;
  }

  &.button {
    font-weight: bold;
    color: #333;
    background: #fdfdfd;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 17px;
    padding-right: 17px;
    display: inline-block;
    cursor: pointer;
    border: none;
    font-size: 0.8em;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    /*min-width: 200px;*/
    &.primary {
      background-color: #ff8800;
      color: #fff;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: lighten(#ff8800, 8%);
      }

      &:active {
        text-decoration: none;
        background-color: darken(#ff8800, 8%);
      }
    }

    &.secondary {
      background-color: transparent;
      color: #ff8800;
      border: solid 2px #ff8800;
      text-decoration: none;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 17px;
      padding-right: 17px;

      &:hover,
      &:focus {
        text-decoration: none;
        color: #ffab4c;
        background-color: transparent;
        border-color: #ffab4c;
      }

      &:active {
        text-decoration: none;
        color: #ff8800;
        background-color: transparent;
        border-color: #ff8800;
      }
    }

    &.success {
      background-color: #62c426;
      color: #fff;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: lighten(#62c426, 8%);
      }

      &:active {
        text-decoration: none;
        background-color: darken(#62c426, 8%);
      }
    }

    &.large {
      font-size: 1.25em;
      padding: 6px 12px;
    }

    &.small {
      padding: 2px 6px;
    }
  }

  &.icon-right {
    i {
      margin-left: 40px;
      margin-right: 0;
    }
  }

  &.icon-left {
    i {
      margin-right: 40px;
      margin-left: 0;
    }
  }

  &.arrow {
    &:before {
      content: "\f0a9";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.cart {
    &:before {
      content: "\f07a";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.check {
    &:before {
      content: "\f00c";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.link-with-icon {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
    color: #ff8800;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: #ffab4c;
    }

    i {
      margin-right: 15px;
    }
  }
  //secondary text link
  &.secondary {
    color: #333;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: #676767;
    }

    &:active {
      text-decoration: none;
      color: #222;
    }
  }
}

span.disabled-link {
  color: #d0d5d9;
  text-decoration: underline;
  font-weight: bold;
  cursor: not-allowed;

  i {
    margin-right: 10px;
  }
}

// input submitbutton styling
input[type="submit"] {
  width: 100%;
  font-weight: bold;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 17px;
  background-color: #ff8800;
  display: inline-block;
  cursor: pointer;
  border: none;
  font-size: 0.8em;
  text-transform: uppercase;
  /*min-width: 200px;*/
  &:disabled {
    background: #ddd;
    cursor: default;
    cursor: not-allowed;
  }

  &.secondary {
    background-color: #2d4059;
    color: #fff;

    &:disabled {
      background-color: #ddd;
      color: #fff;
      cursor: not-allowed;
    }
  }

  &.large {
    font-size: 1.25em;
    padding: 6px 12px;

    &:disabled {
      background-color: #ddd;
      color: #9f9f9f;
      cursor: not-allowed;
    }
  }

  &.arrow {
    &:before {
      content: "\f0a9";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.cart {
    &:before {
      content: "\f07a";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }

  &.check {
    &:before {
      content: "\f00c";
      font-family: FontAwesome;
      padding-right: 15px;
    }
  }
}

input[type="submit"],
button,
a.button {
  &.form-control-button {
    padding: 12px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  button {
    width: auto;
  }

  input[type="submit"] {
    width: auto;
  }
  a.button {
    width: auto;
  }
}
