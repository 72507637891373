.loginScherm {
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
  }
}

.authContainer {
  padding: 20px;
  border: solid 2px #ddd;
  .loginContainer {
    position: relative;
    margin-bottom: 20px;
    .loginControl {
      width: 100%;
      margin-bottom: 10px;
      float: left;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      label {
        width: 100%;
        display: block;
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 4px 0;
      }
      input[type="text"],
      input[type="password"] {
        width: 100%;
        display: block;
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }
  .noAccountYet {
    border-top: solid 2px #ddd;
  }
}

@media only screen and (min-width: 768px) {
  .loginContainer {
    padding-right: 20px;
    margin-bottom: 0;
    border-right: solid 2px #ddd;
  }
  .authContainer {
    .noAccountYet {
      padding: 0 20px;
      border-top: 0;
    }
  }
}
