﻿@import "../themesettings";

.examenOverzichtContainer {
  .table {
    th[class^="col-"],
    td[class^="col-"],
    div[class^="col-"] {
      padding-right: 0;

      &.examen-icon-container,
      &.examen-button-container {
        padding-top: 0px;
        padding-bottom: 15px;
        padding-right: ($grid-gutter-width/2);
      }
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .examenOverzichtContainer {
    .table {
      th[class^="col-"],
      td[class^="col-"],
      div[class^="col-"] {
        &.examen-icon-container,
        &.examen-button-container {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

//smart table styling
.smart-table {
  border-collapse: collapse;

  &.table {
    width: 100%;
  }

  &.table-header-row {
    display: block;
    border-style: solid;
    border-color: #ddd;
    border-width: 2px 2px 2px 2px;
    background-color: #fbfbfc;
    border-width: 0 0 0 0;

    .header-item {
      text-align: left;
      cursor: pointer;
      margin: 0;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      overflow: auto;
      padding-top: 10px;
      padding-bottom: 10px;

      /*padding: 20px 0;*/
      span {
        text-decoration: underline;
      }

      &.st-sort-ascent:after {
        padding-left: 5px;
        text-decoration: none !important;
        font-family: FontAwesome;
        content: "\f0de";
      }

      &.st-sort-descent:after {
        padding-left: 5px;
        text-decoration: none !important;
        font-family: FontAwesome;
        content: "\f0dd";
      }

      &:not([class*="st-sort-"]):after {
        padding-left: 5px;
        text-decoration: none !important;
        font-family: FontAwesome;
        content: "\f0dc";
      }

      &.unsortable:not([class*="st-sort-"]):after {
        display: none;
      }
    }
  }

  &.table-row {
    display: block;
    border-style: solid;
    border-color: #ddd;
    border-width: 0 0 2px 0;
    margin-top: 0;
    border-width: 0 0 2px 0;

    &.transportexamen {
      border-left-color: cornflowerblue;
      background-color: #ecf3ff;
      border-left-width: 4px;

      &:hover {
        background-color: #e6edf9;
      }
    }

    &:hover {
      background-color: #fbfbfc;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      button {
        float: right;
      }
    }

    .table-data {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.mightOverflow:hover {
        display: inline-flex;
        text-overflow: clip;
        white-space: nowrap;
        overflow: unset;

        span {
          background-color: #fbfbfc;
          z-index: 5000;
          padding-right: 20px;
          padding-left: 20px;
          margin-left: -20px;
        }
      }
    }

    &:last-child {
      border-bottom: solid 2px #ddd;
    }
  }

  & .table-footer {
    .pagination {
      padding: 0;
    }

    li {
      &.page1 {
        padding-left: 0;
      }

      display: inline;
      padding-left: 10px;

      //de huidige pagina
      &.active {
        a {
          color: #f80;
          text-decoration: none;
          cursor: default;
        }
      }

      a {
        cursor: pointer;
      }
    }
  }

  .pagination-nav {
    text-decoration: none;
  }

  &.search {
    margin-bottom: 15px;
  }
}
