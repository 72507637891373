﻿@import "../themesettings";

.navi {
  background-color: #333333;
  color: #d3d3d3;
  //1 pixel lager want anders rare rand onder mobile header
  margin: 75px 0 0 0;
  width: 100%;
  height: 100vh;
  position: relative;

  // notification bolletje
  .menuNotification {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
    text-align: center;
    background: #fd8702;
    float: right;
    padding-right: 1px;
  }

  ul {
    background: #333;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;

    li {
      padding-left: 54px;
      padding-right: 54px;
      width: 100%;

      a {
        padding-top: 15px;
        padding-bottom: 15px;
        height: 50px;
        font-size: 0.857em;
        font-weight: bold;
      }

      &:hover {
        background-color: #424242;
        margin-top: -3px;
        padding-top: 3px;
      }

      &.active {
        background-color: #424242;
        box-shadow: inset 5px 0 0 #ff8802;
        margin-top: -3px;
        padding-top: 3px;
        z-index: 9083;
      }

      .inner-menu-item {
        padding-left: 8px;
        border-bottom: solid 2px #424242;
      }
    }

    li:first-child .inner-menu-item {
      border-top: solid 2px #424242;
    }
  }

  a {
    display: inline-block;
    height: 100%;
    color: #d3d3d3;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      text-decoration: none;
      color: $color-nav-icon;
    }

    i {
      color: $color-nav-icon;
      margin-right: 10px;
    }

    &.icon-right {
      i {
        margin-left: 40px;
        margin-right: 0;
      }
    }

    &.icon-left {
      i {
        margin-right: 40px;
        margin-left: 0;
      }
    }

    &.arrow {
      &:before {
        content: "\f0a9";
        font-family: FontAwesome;
        padding-right: 15px;
      }

      /*padding-right: 60px;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><g><g id="keyboard-backspace"><polygon class="st0" points="0,8.9 12.6,8.9 9.4,12.1 10.7,13.3 16,8 10.7,2.7 9.4,3.9 12.6,7.1 0,7.1"/></g></g></svg>');
        background-repeat: no-repeat;
        background-position: right 20px top 50%;
        background-size: 16px;*/
    }

    &.link-with-icon {
      text-transform: uppercase;
      font-size: 0.8em;
      font-weight: bold;
      text-decoration: underline;
      display: inline-block;
      color: #ff8800;

      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: #ffab4c;
      }

      i {
        margin-right: 15px;
      }
    }

    //secondary text link
    &.secondary {
      color: #333;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: #676767;
      }

      &:active {
        text-decoration: none;
        color: #222;
      }
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    float: left;
    height: 100%;
    width: 250px;
    bottom: 0;
    position: absolute;
  }

  .sub-nav {
    margin-top: 100px;

    li {
      a {
        color: #747d84;
        font-size: 0.857em;
        text-transform: none;
        height: auto;
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 0.857em;
        font-weight: bold;

        i {
          color: $color-subnav-icon;
          margin-right: 10px;
        }
      }

      .inner-menu-item {
        border-bottom: none;
      }

      &.active {
        box-shadow: none;
        background-color: #333333;

        a {
          color: #f0f0f0;
        }
      }
    }

    li:first-child {
      .inner-menu-item {
        border-top: none;
      }
    }
  }
}

// add a margin to the main view container when the menu is collapsed,
.navi.collapsed + .container-fluid.main-view {
  margin-top: 76px;
}

// on sm and larger screens always add the margin-top.
@media (min-width: $screen-sm-min) {
  .navi {
    margin: 0;
    padding-top: 40px;

    ul {
      li {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .container-fluid.main-view {
    margin-top: 76px;
    margin-left: 250px;

    @media (max-width: $screen-md-min) {
      margin-left: 0;
    }
  }

  .container-fluid.header-container {
    margin-left: 250px;

    @media (max-width: $screen-md-min) {
      margin-left: 0;
    }
  }

  navi.collapsed + .container-fluid.main-view {
    margin-left: 0;
  }
}

@media (min-width: $screen-md-min) {
  .container-fluid.mainview-notloggedin {
    margin-left: 0;
  }
}
