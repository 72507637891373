.sectionRow {
  margin-bottom: 60px;
}

span.tpb {
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid #f2f2f2;
  padding: 0 5px;
}

.kandidaatStatus {
  display: inline-block;
  &.Geslaagd {
    background-color: #f5f7ee;
    color: #afd09a;
    font-weight: bold;
    padding: 7px 10px;
  }

  &.NietGeslaagd {
    background-color: #d87e8f;
  }

  &.NietAanwezig {
    background-color: #f1a900;
  }
}
