@import "app/stylecomponents/layering";
@import "app/stylecomponents/mixins";

.aanvraagKeurenFormulier {
  padding-bottom: 50px;

  h1 {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.aanvraagKeurenFormulier {
  .col-span-page-title {
    padding-left: 0;
    padding-right: 0;
  }
}

.topRightButton {
  position: static;
  top: auto;
  right: auto;
  margin-bottom: 50px;
  z-index: $z-index-forms;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .topRightButton {
    position: absolute;
    top: 15px;
    right: 20px;

    button {
      margin-left: 0;
    }
  }
}

.indienPoging {
  padding-top: 50px;
  margin-bottom: 50px;
  @include border(solid 2px #ddd, top);
  padding-left: 2em;

  .aanvraagIcon {
    position: absolute;
    margin-left: -1.5em;
    margin-top: 4px;
  }

  .gekeurdDoor {
    margin-top: 10px;
    width: 100%;
    display: block;
  }

  ul {
    list-style: none;
    margin: 40px 0 0 0;
    padding: 0;

    li {
      list-style-type: none;
      font-weight: bold;
      margin-bottom: 10px;
      position: relative;
      padding-left: 1.5em;
      margin-bottom: 20px;
      margin-left: 0.5em;

      span {
        font-weight: normal;
        display: block;
        width: 100%;
        color: #333;
        padding-left: 1em;
        margin-top: 10px;
        margin-bottom: 20px;
        @include box-sizing(border-box);
      }

      &.goedgekeurd {
        color: #62c426;
      }

      &.afgekeurd {
        color: #d80027;
      }

      &.goedgekeurd:before {
        content: "\f00c";
        display: inline-block;
        position: absolute;
        height: 1em;
        width: 1em;
        left: -1.5em;
        font-family: "FontAwesome";
        padding-left: 1em;
        margin-top: 3px;
      }

      &.afgekeurd:before {
        content: "\f00d";
        display: inline-block;
        position: absolute;
        height: 1em;
        width: 1em;
        left: -1.5em;
        font-family: "FontAwesome";
        padding-left: 1em;
        margin-top: 3px;
      }
    }
  }
}

.keurSection {
  padding-bottom: 50px;

  h3 {
    @include border(default, bottom);
    padding-bottom: 20px;
  }

  &.pasFoto,
  &.vog {
    .thumb {
      width: 50px;
      margin-right: 50px;
      float: left;
    }

    span.fileName {
      vertical-align: top;
      padding-top: 20px;
    }
  }

  &.documentNr {
    span.nr {
      font-size: 2em;
    }
  }

  &.personalia,
  &.aanvraagInfo {
    table {
      width: 100%;

      tr {
        td {
          width: 50%;

          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
    button {
      float: right;
      margin-top: 25px;
    }
    button.snoozebutton {
      color: #fd8702;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 7px;
      padding-right: 7px;
      float: none;
    }
    button.reactionbutton {
      float: none;
      margin-top: 0;
      margin-bottom: 15px;
      align-self: flex-end;
    }
  }

  .keurChecks {
    .pretty {
      font-size: 22px;
      margin-bottom: 4px;

      .state {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: -3px;
        }
      }

      .p-danger label {
        color: #d9534f;
      }

      .p-success label {
        color: #5cb85c;
      }
    }
    // https://github.com/lokesh-coder/pretty-checkbox/issues/34
    .pretty.right .state label {
      padding-right: 2rem;
      text-indent: inherit;
    }

    .pretty.right .state label:after,
    .pretty.right .state label:before {
      left: auto;
      right: 0;
    }
    // end
  }
}

.commentList {
  margin: 22px 0 0 0;
  list-style-type: none;
  padding: 0;
  li {
    margin: 0 0 16px 50px;
  }
  .afzender {
    display: inline-block;
    margin: 0 0 0 -25px;
    color: #757575;
    i {
      margin-right: 10px;
    }
  }
  .bericht {
    margin: 0;
    color: #696969;
  }
  .datum {
    font-size: 12px;
    color: #808080;
    display: inline-block;
    margin-left: 10px;
    font-weight: normal;
  }
}

.addReactionLink {
  color: #696969;
  margin: 10px 0 30px 25px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.pasFotoAspectRatioMessages {
  .pasFotoAspectRatioWarning {
    i.fa-exclamation-triangle {
      color: #e9b91c;
    }
  }

  .pasFotoAspectRatioSuccess {
    i.fa-check {
      color: #62c426;
    }
  }
}

.gegevensWijkenAfVanOrigineelWarning {
  i.fa-exclamation-triangle {
    color: #e9b91c;
  }
}

.keurButton {
  float: left;
  margin-right: 1.875rem;
  &.goedkeuren {
    background-color: #62c426;
    color: #fff;

    &[disabled="disabled"] {
      background-color: #ddd !important;
    }
  }

  &.afkeuren {
    background-color: #ff8800;
    color: #fff;

    &[disabled="disabled"] {
      background-color: #ddd !important;
    }
  }

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    margin-left: 10px;
    vertical-align: middle;
    margin-top: -2px;
    display: inline-block;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="459px" height="459px" viewBox="0 0 459 459" style="enable-background:new 0 0 459 459;" xml:space="preserve"><g><g id="keyboard-backspace"><polygon fill="#fff" points="459,204 96.9,204 188.7,112.2 153,76.5 0,229.5 153,382.5 188.7,346.8 96.9,255 459,255"/></g></g></svg>');
    background-repeat: no-repeat;
    background-size: 16px;
  }
}

.afkeurRedenen {
  h3 {
    color: #d80027;

    i {
      margin-right: 20px;
    }
  }
}

.afkeurRedenenLijst {
  .reden {
    &:last-child {
      margin-bottom: 30px;
    }
  }
}

.acknowledgeButton {
  color: #fff;
  margin-left: 20px;
  padding: 20px;
  float: right;

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    margin-left: 10px;
    vertical-align: middle;
    margin-top: -2px;
    display: inline-block;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="459px" height="459px" viewBox="0 0 459 459" style="enable-background:new 0 0 459 459;" xml:space="preserve"><g><g id="keyboard-backspace"><polygon fill="#fff" points="459,204 96.9,204 188.7,112.2 153,76.5 0,229.5 153,382.5 188.7,346.8 96.9,255 459,255"/></g></g></svg>');
    background-repeat: no-repeat;
    background-size: 16px;
  }

  &:disabled {
    background: #ddd !important;
    color: #fff !important;
  }
}

.acknowledgeKeuringMelding {
  display: block;
  width: 100%;
  text-align: right;
  color: #d80027;
  margin-bottom: 50px;
}

.iconLink {
  cursor: pointer;
}

.cropper {
  position: fixed;
  top: 2%;
  bottom: 2%;
  left: 5%;
  right: 5%;
  padding: 10px;
  overflow: auto;
  z-index: $cropper;
  background: #fff;

  h2 {
    margin: 0 0 10px 0;
  }

  .closeCropper {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    min-width: 0;
  }
}

.overlay-background {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: $overlay-background;
}

.grey-area {
  background: #f8f8f8;
  padding: 20px;
  position: relative;

  p {
    margin-bottom: 20px;
    text-align: center;
  }
}

.imageContainer {
  width: 50%;
  height: 66.6%;
  margin: 0 auto;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.4;
    z-index: 9999;
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 178 242" style="enable-background:new 0 0 178 242;" xml:space="preserve"><style type="text/css">.st0{fill:#010101;}</style><path class="st0" d="M0,204.9c4.3-4.3,7.9-7.4,10.2-8.7l42.9-19.1c3.3-1.8,5.4-5.3,5.4-9.1v-13c-2.6-3.4-10.2-14.2-13.8-28.4 l-2-30.4V75.2c-0.2-2.2-1.1-15,8.2-25.6c8.1-9.2,21.2-21.7,38.9-21.7c17.8,0,30.9,12.5,38.9,21.7c9.3,10.6,8.4,23.5,8.2,25.6v20.9 l-5.2,32.4c-2.8,8.3-6.7,15.9-11.6,22.8c-1,1.4-1.9,2.6-2.8,3.7v13.3c0,4,2.2,7.5,5.7,9.3l41.5,18.8c3,1.5,7.8,5.7,13.3,11.4V0H0V204.9z"/></svg>');
    background-size: cover;
  }
}

.cropperDialog {
  height: 80vh;
}

.cropper-controls {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cropperCta {
  text-align: center;
  margin-top: 50px;

  button {
    display: inline-block;

    &.primary {
      i {
        margin-left: 25px;
      }
    }
  }
}

.keurable-aanvraag-table {
  .aanvraag-row {
    cursor: pointer;
  }
}

.aanvraaginfo-container {
  .isGemeente {
    background-color: #ffe7bd;
  }
}

.table-data {
  &.isGemeente {
    border-left-color: #ffe7bd;
    border-left-width: 5px;
    border-left-style: solid;
  }

  &.isNotGemeente {
    border-left-color: #bdd6ff;
    border-left-width: 5px;
    border-left-style: solid;
  }
}

@media only screen and (min-width: 768px) {
  .cropper {
    top: 5%;
    bottom: 5%;
    left: 20%;
    right: 20%;
    padding: 30px;

    h2 {
      margin: 20px 0;
    }

    .closeCropper {
      top: 40px;
      right: 30px;
    }
  }

  .grey-area {
    padding: 50px;

    p {
      margin-bottom: 50px;
    }
  }

  .imageContainer {
    width: 25.5%;
    height: 30%;
  }
}
.img-container {
  max-width: 800px;
  float: left;
}

.cropper-wrapper {
  max-height: 50%;
  img {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .cropper {
    top: 5%;
    bottom: 5%;
    left: 30%;
    right: 30%;
    padding: 30px;

    h2 {
      margin: 20px 0;
    }

    .closeCropper {
      top: 40px;
      right: 30px;
    }
  }

  .grey-area {
    padding: 50px;

    p {
      margin-bottom: 50px;
    }
  }

  .imageContainer {
    width: 30%;
    height: 40%;
  }
}

.foundpas-item-container {
  border-collapse: collapse;
  width: 100%;
  margin-top: 26px;

  .pasType {
    border: solid 2px #f2f2f2;
    font-weight: bold;
    padding: 2px 2px 2px 2px;

    &.tpb {
      color: #a5aeb5;
    }
  }

  tr {
    border-bottom: solid 2px #f2f2f2;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td.checkbox-column {
    margin-right: 0px;
  }

  td.name-column {
  }

  td.pasnummer-column {
  }

  td.verloopdatum-column {
    float: right;
    font-size: 12px;

    i {
      margin-left: 20px;
      color: black;
      cursor: pointer;
      font-size: 18px;

      &.opened {
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }

      &.closed {
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }
    }
  }

  td.extra-info-row {
    transform: scale(
      1
    ); /* you need a scale here to allow it to transition in both directions */
    transition: 0.5s all ease;
    background-color: #f5f5f5;
    overflow: hidden;
    color: black;

    &.hiddenAnimatable {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.visible {
      border-top: solid 2px #f2f2f2;
      max-height: 400px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .row {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .afkeurreden-row {
      color: #e06e84;
      font-weight: bold;

      [class^="col-"] {
        text-decoration: none !important;
      }
    }
  }

  .foundpas-item {
    cursor: pointer;

    .green {
      color: #9dcc89;
      font-weight: bold;
    }

    .red {
      color: #e06e84;
      font-weight: bold;
    }

    .orange {
      color: #f5a623;
      font-weight: bold;
    }

    &.ongeldig {
      color: #e06e84;

      [class^="col-"] {
        text-decoration: line-through;
      }
    }
  }

  thead {
    font-weight: bold;
  }
}

.aanvraagTypeContainer {
  border-bottom: solid 2px #ddd;
  padding-bottom: 20px;
}

.werkgeversHead {
  padding-top: 20px;
  padding-bottom: 20px;
}

.extraWerkgeverContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.werkgeverRow {
  margin-bottom: 10px;
}
