﻿@import "layering";
@import "../themesettings";

//
// SweetAlert styling, overriding default library styling for Aanstellingspas theme
//

.swal2-container * {
  font-family: "Open Sans", sans-serif !important;
}

.swal2-modal {
  border-radius: 0;
  z-index: $modal;

  button {
    border-radius: 0 !important;
  }
}

.swal2-overlay {
  z-index: $ui-blocker;
}

.swal2-confirm {
  margin-bottom: 20px !important;
  background: #ff8800 !important;
}

.swal2-cancel {
  margin-bottom: 20px !important;
  background: #ddd !important;
}

.swal-footer {
  background-color: #fbfbfc;
  margin-top: 32px;
  border-top: solid 2px #f2f2f2;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.swal-title {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #747d84;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 30px !important;
  text-transform: none;
}

.swal-text {
  padding-left: 50px;
  padding-right: 50px;
}

.swal-modal {
  border-radius: unset;
}

.swal-button {
  border-radius: 0;

  &:focus {
    box-shadow: none;
  }
}

.swal-button--confirm {
  background-color: $color-primary;
  color: #fff;

  &:hover {
    background-color: lighten($color-primary, 10%);
  }
}

.swal-button--cancel {
  background-color: Transparent;
  text-transform: none;
  color: $back-link-text-color;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: lighten($back-link-text-color, 10%);
    background-color: Transparent;
  }
}
