﻿@import "layering";

//
// first time bootstrapping loading bar
//
#bootstrapping-loading-bar {
  pointer-events: all;
  z-index: $bootstrapping-app-loading-bar;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  .loadingMessage {
    position: absolute;
    width: 240px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-left: -120px;
    margin-top: -100px;
    p {
      width: 100%;
      text-align: center;
    }
  }
}

// makes loading bar full screen with UI blocking
#loading-bar {
  pointer-events: all;
  z-index: $app-loading-bar;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
}
#loading-bar-spinner {
  z-index: $app-loading-bar-spinner;
  width: 100%;
  height: 100%;
  .spinner-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    border-width: 3px;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
  }
}
