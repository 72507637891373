﻿/*
	Deze styling is nodig voor de fileuploader in pasaanvraag, als pasaanvraag ooit retired gaat mag deze file weg
*/

.pasaanvraag-legacy-bvouploader {
  .bvoContainer {
    margin-bottom: 120px;
  }

  .disabled {
    color: #aaa;
    cursor: not-allowed;
  }

  .uploadReady {
    border-color: #99c727;
  }

  .uploader {
    padding-bottom: 100px;

    form {
      padding: 0;

      fieldset {
        padding: 0;
        margin: 0;
        border: none;

        legend {
          display: none;
        }
      }
    }

    .uploadField {
      background: #f8f8f8;
      padding: 20px;
      border: solid 1px #ddd;

      .progress {
        width: 100%;
        font-weight: bold;
        text-align: center;

        .progressAnnounce {
          &.ready {
            color: #99c727;
          }

          &.notReady {
            color: #333;
          }
        }

        .progressFilling {
          margin: 20px 0;
          background: #ff8800;
          border-radius: 3px;
          color: #fff;
          text-shadow: 0px 1px 1px darken(#ff8800, 36%);
          box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 3px inset;
          padding: 2px;
        }
      }
    }

    .uploadPreview {
      position: relative;

      img {
        border: solid 1px #ddd;
        margin-bottom: 20px;
        max-width: 100%;
        max-height: 320px;
      }
    }
  }

  .drop-box {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background: #f8f8f8;

    &.dragover {
      border-color: #ff8800;
      background: #ffedd8;
    }
  }

  .bvoUploader {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hidden-element;

    & ~ label {
      font-weight: bold;
      color: white;
      width: 100%;
      padding: 20px;
      background-color: #ff8800;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      cursor: pointer;

      &.show-small img {
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px;
      }
    }

    &:disabled ~ label {
      background-color: #ddd;
      color: #9f9f9f;
      cursor: not-allowed;
    }

    &:focus ~ label,
    &:focus ~ label:hover {
      background-color: lighten(#ff8800, 20%);
    }

    &:focus ~ label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

  @media only screen and (max-width: 768px) {
    .bvoErrors {
      div {
        div {
          position: fixed;
          top: 0;
          left: 0;
          z-index: $inline-form-errors;
          width: 100%;
          height: 100%;
          background: #fecaca;
          border: dashed 2px #d9082e;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          color: #d9082e;
          cursor: pointer;

          img {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 20px;
            right: 20px;
          }

          .errorIcon {
            font-size: 2em;
            font-weight: bold;
            display: block;
            position: absolute;
            left: 10px;
            top: 10px;
          }

          span {
            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
            font-weight: bold;
            text-align: center;
            margin-top: -10px;
            padding: 0 20px 0 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }

          button.secondary {
            background: none;
            border: none;
            padding: 0 0 7px 0;
            border-bottom: solid 2px #333;
            transition: border-bottom linear 0.1s;
            display: block;
            position: absolute;
            top: 80%;
            left: 50%;
            margin-left: -30px;
            color: #333;
            text-shadow: none;
            cursor: pointer;

            &:hover {
              background: none;
              border: none;
              padding: 0 0 7px 0;
              border-bottom: solid 2px #fecaca;
              transition: border-bottom linear 0.1s;
              color: #333;
              text-shadow: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .bvoErrors {
      div {
        div {
          top: -2px;
          position: absolute;
          left: -2px;
          width: 100%;
          height: 100%;
          background: #fecaca;
          border: dashed 2px #d9082e;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
          color: #d9082e;
          cursor: pointer;

          img {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 20px;
            right: 20px;
          }

          .errorIcon {
            font-size: 2em;
            font-weight: bold;
            display: block;
            position: absolute;
            left: 10px;
            top: 10px;
          }

          span {
            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
            font-weight: bold;
            text-align: center;
            margin-top: -10px;
            padding: 0 20px 0 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .bvoEisen {
    line-height: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    img {
      margin: 3px 10px 0 0;
      display: block;
      float: left;
    }
  }

  .bvoForm {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;
    z-index: $fixed-bottom-form;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.22) 0 1px 1px;
    border-top: solid 1px #dfdfdf;
    @include box-sizing(border-box);

    &.bvoFormReady {
      background: #f8e6d2;
      border: solid 1px #e0c4a4;
    }

    .primary {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .bvoPreview {
    ul {
      list-style-type: none;
      margin: 0 !important;
      padding: 0;
      overflow: auto;

      li {
        margin-bottom: 20px;
        width: 100%;
        overflow-x: hidden;
        float: left;
        padding-bottom: 20px;
        @include border(solid 2px #ddd, "bottom");
        text-align: center;

        img.thumb {
          width: 100%;
        }

        &:only-child {
          border-bottom: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .bvoForm {
      .primary {
        width: auto;
        margin-bottom: 0;
      }
    }

    .bvoPreview {
      ul {
        li {
          text-align: left;
        }
      }
    }
  }

  .bvoInfo {
    padding: 20px 10px 0 10px;
    background: #e0e8f2;
  }

  .bvoBestandUploader.disabled {
    color: #aaa;

    label.fileUploadLabel {
      background-color: #ddd;
      color: #9f9f9f;
    }

    input[type="submit"] {
      background-color: #ddd;
      color: #9f9f9f;
    }

    button {
      background-color: #ddd;
      color: #9f9f9f;
    }
  }

  .pdfIcon {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    .extention {
      font-size: 2.4em;
      font-weight: bold;
      display: inline-block;
      padding: 20px;
      text-transform: uppercase;
      border: solid 2px #ddd;
      color: #777;
    }
  }

  .documentContainer {
    ul {
      width: 100%;
      position: relative;
      overflow: auto;
      margin-left: 0;

      li {
        width: 100%;
        margin-bottom: 15px;

        a {
          width: 100%;
          display: block;
          text-align: left;
          line-height: 45px;

          img {
            display: inline;
            vertical-align: middle;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .correctBvo {
    padding-bottom: 120px;

    .documentContainer {
      ul {
        padding: 0;
        margin: 0;

        li {
          width: 100%;
          padding: 20px 0;

          &:first-of-type {
            padding-left: 0;
            padding: 0 0 20px 0;
          }
        }
      }
    }
  }

  .keuringshistorie {
    .col-12 {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .afkeuring {
      border-bottom: solid 2px #ddd;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .bvoDone {
      .documentContainer {
        ul {
          width: 100%;
          position: relative;
          overflow: auto;
        }
      }
    }

    .bvoUploader {
      & + label {
        width: auto;
      }
    }

    .drop-box {
      width: 100%;
      min-height: 50px;
      padding: 30px;
      margin-bottom: 40px;
      border: dashed 2px #b7b7b7;
      text-align: center;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      transition: padding ease-in-out 0.5s;

      &.dragover {
        border-color: #ff8800;
        background: #ffedd8;
      }

      &.padded {
        padding: 73px 30px 30px 30px;
        transition: padding-top ease-in-out 0.5s;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .drop-box {
      background: transparent;
    }
  }

  .my-messages {
    transition: 0.5s linear all;
  }

  .my-messages.ng-active {
    // messages are visible
  }

  .my-messages.ng-inactive {
    // messages are hidden
  }

  .some-message {
    transition: 0.5s linear all;
  }

  .ng-enter-prepare {
    display: none;
  }

  .some-message.ng-enter {
    opacity: 0;
  }

  .some-message.ng-enter.ng-enter-active {
    opacity: 1;
  }

  .some-message.ng-leave {
    opacity: 1;
  }

  .some-message.ng-leave.ng-leave-active {
    opacity: 0;
  }
}
