.tab-bar-container {
  .tab-bar {
    padding-left: 0px;
  }
}

.organisatieGegevens {
  padding-top: 20px;
}

.profileControl {
  &.flex {
    display: flex;
    align-items: center;

    label {
      margin-right: 10px;
    }
  }
}
