﻿@import "../_themesettings.scss";

header {
  height: 76px;
  width: 100%;
  z-index: $main-header;
  position: fixed;
  top: 0;
  margin: 0;

  h1 {
    margin: 0;
    padding: 0;
  }

  .header-content {
    height: 100%;
    background-color: #333333;

    .row {
      margin-top: 25px;
    }

    span.backoffice-warning {
      animation: blinker 1s linear infinite;
      color: red;

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }

    .header-hamburger-icon {
      a {
        i {
          font-size: 29px !important;
          color: #fff !important;
        }
      }
    }

    .header-logo {
      text-align: center;
    }

    .header-title {
      float: left;

      h1 {
        margin: 0;
        padding: 0;
      }
    }

    .header-shopping-cart {
      text-align: right;

      a {
        i {
          font-size: 20px;
          color: #fff;
        }
      }

      /*.shopping-cart-bolletje {
                position: fixed;
                right: 31px;
                color: orange;
                font-size: 10px;
            }*/
    }

    .header-login {
      text-align: right;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  header {
    .header-content {
      .header-shopping-cart {
        a {
          text-align: right;
          color: #a5aeb5;
          text-decoration: none;
          font-size: 12px;

          i {
            color: #a5aeb5;
            font-size: 12px;
          }
        }
      }
    }

    .header-login {
      a {
        color: black;
      }
    }

    .header-title {
      height: 76px;
    }

    .header-profile {
      height: 76px;
    }
  }
}

@media (min-width: $screen-md-min) {
  header {
    .header-content {
      background-color: #fff;

      .row {
        margin-top: 0;
      }

      .header-title h1 {
        height: 76px;
        padding-top: 25px;
        margin-left: ($grid-gutter-width/2) !important;
      }

      .header-shopping-cart {
        text-align: right;
        height: 76px;

        .inner {
          padding-top: 25px;
          padding-bottom: 25px;
          border-left: solid 2px #edeef2;
          border-right: solid 2px #edeef2;
          text-align: center;
          float: right;
          height: 76px;
          min-width: 84px;

          a {
            color: #a5aeb5;
            font-size: 12px;
            padding-left: 20px;
            padding-right: 20px;

            i {
              font-size: 12px;
              color: #a5aeb5;
            }
          }
        }
      }

      .header-profile {
        color: #a5aeb5;
        font-weight: bold;
        font-size: 12px;
        text-align: right;
        height: 76px;
        text-align: center !important;

        .inner {
          white-space: nowrap;
          cursor: pointer;
          float: right;
          margin-left: 50px; //zodat het uitgeklapte menutje niet naast de inner valt

          .header-profile-username {
            padding-top: 29px;
            padding-bottom: 25px;
            padding-left: 20px;
            padding-right: 20px;
            border-left: solid 2px #edeef2;
            border-right: solid 2px #edeef2;
          }

          i {
            &.fa-user {
              margin-right: 20px;
            }

            &.fa-caret-down {
              margin-left: 15px;
            }
          }
        }

        .item-container {
          cursor: default;
          width: 274px;
          background-color: #f7f7fa;
          text-align: left;
          float: right;

          .item {
            padding-left: 25px;
            height: 50px;
            line-height: 50px;
            text-align: left;
            cursor: pointer;
            border-top: solid 2px #edeef2;

            i {
              margin-right: 20px;
            }

            &:hover {
              background-color: lighten(#f7f7fa, 20);
            }
          }
        }
      }
    }

    .lefthand-navigation-facade {
      float: left;
      background-color: #333333;
      height: 100%;
      width: 250px;

      a {
        display: block;
        margin-top: 25px;
        margin-left: 30px;
      }
    }

    .lefthand-navigation-facade-notloggedin {
      background-color: #fff;
    }

    .header-login {
      margin-top: 25px;
      padding-right: 30px;

      a {
        color: black !important;
      }
    }
  }
}
