@import "../../stylecomponents/mixins";

.dashboard {
  .table-container {
    padding-left: 0;
    padding-right: 0;
  }

  .dashboard-table {
    padding-left: 0px;
    padding-right: 0px;

    .dashboard-table-title {
      display: flex;
      justify-content: space-between;
      align-items: center; /* align vertical */
      font-size: 16px;
      background-color: #333333;
      color: #89969e;
      height: 50px;
      padding-left: 20px;
      text-align: center;

      h3 {
        text-transform: none;
      }

      .dashboard-table-title-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50px;
        background-color: #fd8702;

        i {
          margin-left: 10px;
          color: white;
        }
      }
    }

    .dashboard-table-header {
      display: flex;
      justify-content: flex-start; /* align horizontal */
      align-items: center; /* align vertical */
      height: 60px;
      background-color: #f7f7fa;
      text-transform: uppercase;
      color: #89969e;
      font-size: 12px;
      font-weight: bold;
      border-bottom: solid 2px #f2f2f2;

      div {
        padding: 20px;
      }
    }

    .dashboard-table-row {
      display: flex;
      justify-content: flex-start; /* align horizontal */
      align-items: center; /* align vertical */
      height: 50px;
      /*border-bottom: solid 2px #f2f2f2;*/
      background-color: #ffffff;
      color: #a5aeb5;
      padding-left: 10px;

      i {
        color: $grey-tint-one;

        &.ingediend {
        }

        &.inkeuring {
        }

        &.afgekeurd {
          color: #d72948;
        }

        &.goedgekeurd {
          color: #6fc43a;
        }

        &.gecorrigeerd {
        }

        &.verzonden {
        }
      }

      div {
        padding: 10px;
      }

      a {
        color: $grey-tint-one;

        &:hover {
          color: $grey-tint-two;
        }
      }

      .dashboard-table-row-name {
        padding: 10px;
      }

      .dashboard-table-row-knop {
        text-align: right;
        padding-right: 10px;
      }

      .dashboard-table-row-plaatsnaam {
        padding-left: 10px;
      }

      i {
        &.fa-eye {
          cursor: pointer;
        }
      }
    }

    .dashboard-table-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center; /* align vertical */
      height: 60px;
      background-color: #f7f7fa;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      border-bottom: solid 2px #f2f2f2;
      text-transform: none;

      span {
        float: right;
        color: red;

        a {
          color: $grey-tint-one;
        }

        i {
          margin-left: 10px;
          color: $grey-tint-one;
        }

        &:hover a {
          color: $grey-tint-two;
        }

        &:hover i {
          color: $grey-tint-two;
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .dashboard {
    .dashboard-table {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
    }
  }
}
