﻿@import "layering";
@import "mixins";

.notificationBox {
  width: 100%;
  position: fixed;
  top: 85px;
  padding-left: 10px;
  padding-right: 10px;

  .notification-container {
    z-index: $notification-box;
    width: 100%;
    /*height: 90px;*/
    background-color: white;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 10px;

    &.success {
      background-color: #f3f7e4;
      color: #72cf4b;
      border: solid 2px #9dcc89;
    }

    &.info {
      background-color: #e3f0ff;
      color: #66aaf9;
      border: solid 2px #74acee;
    }

    &.warn {
      background-color: #f7f5e9;
      color: #f9aa22;
      border: solid 2px #fbb640;
    }

    &.error {
      background-color: #f9edee;
      color: #e56878;
      border: solid 2px #e06e84;
    }
  }
}

@media only screen and (min-width: $screen-tablet) {
  .notificationBox {
    width: 500px;
    right: 15px;
    padding: 0;
    cursor: pointer;

    .notification-container {
      min-height: 90px;
      display: flex;
      padding: 0;
    }

    .notification-container:hover {
      .cross {
        opacity: 1;
      }
    }

    .notification-icon-container {
      float: left;
      min-width: 70px;
      color: white;
      text-align: center;

      i {
        margin-top: 35px;
      }

      &.success {
        background-color: #8dcf71;
      }

      &.info {
        background-color: #74acee;
      }

      &.warn {
        background-color: #fbb640;
      }

      &.error {
        background-color: #e55163;
      }
    }

    .notification-message-container {
      float: right;
      padding: 30px;
      padding-right: 60px;
      width: 100%;
    }

    .cross {
      margin-top: 35px;
      margin-right: 30px;
      opacity: 0.3;
    }
  }
}
