﻿@import "mixins";

// body has a top margin to prevent content under fixed header of 60px height
body {
  background: white;
  overflow: auto !important;
  position: relative;

  &.disableScrolling {
    overflow: hidden;
  }
}

// create an offset from the header (usually for the first row in the template)
.row.offset-top {
  margin-top: 20px;
}

//create an offset from the bottom of a row (usually for presentational purposes to create a white space in the bottom of container)
.row.offset-bottom {
  margin-bottom: 50px;

  &.offset-bottom-small {
    margin-bottom: 20px !important;
  }

  &.offset-bottom-medium {
    margin-bottom: 40px !important;
  }

  &.offset-bottom-large {
    margin-bottom: 50px !important;
  }
}

/* currently only used in pasaanvraag, update this message when you use it somewhere else please */
.dialog {
  box-shadow: 0 2px 12px #edeef2;
  padding: 60px;

  h1 {
    margin-top: 0;
  }

  h2 {
    margin-top: 0;
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }
}

.view-tile {
  background: #fff;
  width: 100%;
  padding: 0px 0px 15px 0;
  margin-bottom: ($grid-gutter-width/2);

  &.no-bottom-padding {
    padding-bottom: 0px;
  }
}

.col-span-page-title {
  background-color: #fbfbfd;
  border-top: solid 2px #edeff1;
  border-bottom: solid 2px #edeff1;
  margin-top: 0;
  margin-bottom: 20px;

  h3 {
    margin: 20px 0 20px 0;
    line-height: 10px;
    font-size: 12px;
  }
}

//utility
.hidden {
  display: none;
}

div.pageTitle-container {
  background-color: white;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 30px;
}

@media only screen and (min-width: $screen-sm) {
  body {
    background: #edeef2;
  }

  .view-tile {
    padding: ($grid-gutter-width/2);
  }

  .col-span-page-title {
    margin-top: -1 * ($grid-gutter-width/2);
  }

  div.pageTitle-container {
    margin-bottom: ($grid-gutter-width/2);
    border-bottom: none;
  }
}

@media only screen and (min-width: $screen-md-min) {
  div.pageTitle-container {
    margin-bottom: 0;
  }

  .container-fluid.main-view {
    padding-top: 64px;
  }
}
