@import "app/stylecomponents/mixins";
@import "app/stylecomponents/layering";

.secretariaatexamenwijzig {
	.detailHeader {
		h3 {
			font-size: 12px;
		}
	}

	.deelnemerNotification {
		margin-top: 15px;
		margin-bottom: 15px;
		margin-left: 30px;
		margin-right: 30px;
		font-weight: bold;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		overflow: auto;

		&.examenInDeToekomst {
			color: #d6ca8e;
			background-color: #f7f5e9;
			border: solid 2px #d6ca8e;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
		width: 100%;
		padding: 0 50px 0 50px;
	}

	tr {
		border-bottom: solid 2px #f3f3f3;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 14px;
		font-weight: 600;
		color: #a5aeb5;

		&:last-child {
			border-bottom: none;
		}

		&:nth-child(even) {
			background-color: #fcfcfd;
		}

		&:nth-child(odd) {
			background-color: #fff;
		}

		td {
			padding: 0 30px 0 30px;
		}

		th {
			text-align: left;
			padding: 0 30px 20px 30px;
			text-transform: uppercase;
		}
	}

	.deelnemerRow {
		line-height: 40px;

		i.editDeelnemer {
			cursor: pointer;
			float: right;
			padding-top: 12px;
		}

		.editTussenvoegselContainer {
			padding-right: 0px !important;
		}

		.editAchternaamContainer {
			padding-right: 0px !important;
			padding-left: 10px !important;
		}

		select {
			width: 100% !important;
			height: 38px !important;
			border: 2px solid #edeef2;
			font-size: 14px;
			padding-left: 8px;
			color: #a5aeb5;

			option:not(:checked) {
				background-color: white;
			}

			&.geslaagd {
				background-color: #edffde;
			}

			&.inAfwachting {
				background-color: white;
			}

			&.nietGeslaagd {
				background-color: #ffe0e4;
			}

			&.nietAanwezig {
				background-color: #f7f5e9;
			}

			&:disabled {
				cursor: not-allowed;
			}
		}

		.deelnemerStatus {
			&.Geslaagd {
				color: #afd09a;
			}

			&.NietGeslaagd {
				color: #d87e8f;
			}

			&.NietAanwezig {
				color: #f1a900;
			}

			.deelnemerStatusIcon {
				/*margin-left: -15px;*/
				margin-right: 15px;
			}
		}
	}

	.sectionRow {
		margin-bottom: 60px;
	}

	.verwijderenLink {
		margin-top: 15px;
	}

	.danger {
		color: #d0021b;
		cursor: pointer;

		&:hover {
			color: #ff1c45;
		}
	}
}

.notifyGedelegeerdenModal {
	p {
		margin: 10px 0 0 0;
	}
}

.not-subbed-message {
	background-color: #e3f0ff;
	border: solid 2px #74acee;
	display: flex;
	width: 100%;
	min-height: 70px;
}

.not-subbed-message__body {
	display: flex;
	align-items: center;
	width: 100%;
	color: #74acee;
	font-weight: bold;
	padding-left: 25px;
}
