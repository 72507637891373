﻿@import "layering";
@import "../_themesettings.scss";

.uiBlocker {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: $ui-blocker;
	background: rgba(0, 0, 0, 0.7);
}

.modalContainer {
	position: fixed;
	left: 0;
	z-index: $modal;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: auto;
	width: 100%;
	top: 7%;
	background: #fff;
	height: 100%;
	border: solid 1px #ddd;
}

@media screen and (min-width: $screen-sm-min) {
	.modalContainer {
		width: 60%;
		height: auto;
		left: 20%;
		padding-top: 30px;
		max-height: 90%;
	}
}

@media screen and (min-width: $screen-md-min) {
	.modalContainer {
		width: 33%;
		left: 33%;
		height: auto;
		padding-top: 30px;
	}
}
