.secretariaatOverzichtBestellingen,
.adminOverzichtBestellingen,
.secretariaatPassenOverzicht {
  .stickyBottom {
    margin-top: 35px;
  }
}

.daterangepicker {
  .button {
    &.primary {
      border: 2px solid #ff8800;
      margin-left: 8px;
      &:hover {
        border-color: lighten(#ff8800, 8%);
      }
    }
  }
}
